<template>
  <v-container
    id="signup"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="9"
      >
        <v-slide-y-transition appear>
          <base-material-wizard
            v-model="tab"
            :available-steps="availableSteps"
            :items="tabs"
            class="mx-auto"
            color="primary"
            light
            @next="next()"
            @previous="previous()"
          >
            <v-overlay :value="loading">
              <v-progress-circular
                indeterminate
                absolute
                size="64"
              />
            </v-overlay>

            <v-tab-item class="pb-6">
              <validation-observer
                ref="preliminary"
                v-slot="{}"
              >
                <form>
                  <v-row
                    class="mx-auto"
                    justify="space-around"
                    style="max-width: 500px;"
                  >
                    <v-col cols="12">
                      <div class="text-center display-1 font-weight-light">
                        {{ $tc('wizards.preliminary', 1) }}
                      </div>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="country"
                      >
                        <v-select
                          v-model="signup.country"
                          :items="countries"
                          color="primary"
                          :label="$tc('country', 1)"
                          dense
                          :error-messages="errors"
                          prepend-icon="mdi-web"
                          validate-on-blur
                          @input="resetCountryRules()"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="language"
                      >
                        <v-select
                          v-model="signup.language"
                          :items="$store.state.locales"
                          color="primary"
                          :label="$tc('language', 1)"
                          :error-messages="errors"
                          return-object
                          prepend-icon="mdi-translate"
                          validate-on-blur
                          @input="$store.commit('SET_LOCALE', signup.language.value); $i18n.locale = signup.language.value"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col cols="12">
                      <div class="text-center display-1 font-weight-light">
                        {{ $tc('wizards.requirements', 1) }}
                      </div>
                    </v-col>

                    <v-col cols="12">
                      <div class="body-1">
                        {{ $tc("placeholders." + signup.country + ".requirements", 1) }}
                      </div>
                    </v-col>
                  </v-row>
                </form>
              </validation-observer>
            </v-tab-item>

            <v-tab-item class="pb-6">
              <validation-observer
                ref="content"
                v-slot="{}"
              >
                <form>
                  <validation-provider
                    rules="required"
                    name="licensed"
                  >
                    <input
                      :value="signup.licensed"
                      type="hidden"
                    >
                  </validation-provider>

                  <validation-provider
                    rules="required"
                    name="selectability"
                  >
                    <input
                      :value="signup.selectability"
                      type="hidden"
                    >
                  </validation-provider>

                  <v-row
                    class="mx-auto"
                    justify="space-around"
                    style="max-width: 500px;"
                  >
                    <v-col cols="12">
                      <div class="text-center display-1 grey--text font-weight-light">
                        {{ $tc('wizards.content', 1) }}
                      </div>
                    </v-col>

                    <v-col cols="12">
                      <v-item-group
                        v-model="signup.licensed"
                        class="row"
                        mandatory
                      >
                        <v-item
                          v-for="(license, i) in licenses"
                          v-show="signup.licensed === i || !$route.query.licensed"
                          :key="i"
                        >
                          <template #default="{ active, toggle }">
                            <v-col
                              class="text-center"
                              :cols="$route.query.licensed ? 12 : 6"
                            >
                              <v-card
                                :class="active ? 'primary--text' : 'grey--text'"
                                class="mx-auto pa-8 d-inline-block v-card--account"
                                outlined
                                @click="toggle"
                              >
                                <v-icon
                                  large
                                  v-text="license.icon"
                                />
                              </v-card>

                              <div
                                :class="active ? 'primary--text' : 'grey--text'"
                                class="text-uppercase subtitle-2"
                              >
                                {{ $tc(license.type, 1) }}
                              </div>
                            </v-col>
                          </template>
                        </v-item>
                      </v-item-group>
                    </v-col>

                    <v-col
                      v-if="signup.licensed !== null"
                      cols="12"
                    >
                      <div class="text-center display-1 grey--text font-weight-light mt-6">
                        {{ $tc('wizards.version', 1) }}
                      </div>
                    </v-col>

                    <v-col
                      v-if="signup.licensed !== null"
                      cols="12"
                    >
                      <v-item-group
                        v-model="signup.selectability"
                        class="row"
                        mandatory
                      >
                        <v-item
                          v-for="(select, i) in selectabilities"
                          :key="i"
                        >
                          <template #default="{ active, toggle }">
                            <v-col
                              class="text-center"
                              cols="6"
                            >
                              <v-card
                                :class="active ? 'primary--text' : 'grey--text'"
                                class="mx-auto pa-8 d-inline-block v-card--account"
                                outlined
                                @click="toggle"
                              >
                                <v-icon
                                  large
                                  v-text="select.icon"
                                />
                              </v-card>

                              <div
                                :class="active ? 'primary--text' : 'grey--text'"
                                class="text-uppercase subtitle-2"
                              >
                                {{ $tc(select.type, 1) }} - &euro;{{ pricing[signup.licensed][i].amount }}<br>({{ $tc("exvat", 1) }}/{{ $tc("yearly", 1) }})
                              </div>
                            </v-col>
                          </template>
                        </v-item>
                      </v-item-group>
                    </v-col>
                  </v-row>
                </form>
              </validation-observer>
            </v-tab-item>

            <v-tab-item class="pb-6">
              <validation-observer
                ref="account"
                v-slot="{}"
              >
                <form>
                  <v-row
                    class="mx-auto"
                    justify="space-around"
                    style="max-width: 500px;"
                  >
                    <v-col cols="12">
                      <div class="text-center display-1 font-weight-light">
                        {{ $tc('wizards.account', 1) }}
                      </div>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|min:5|max:40"
                        name="contact"
                      >
                        <v-text-field
                          v-model="signup.contact"
                          color="primary"
                          :label="$tc('contact', 1) + '*'"
                          :placeholder="$tc('placeholders.' + signup.country + '.contact', 1)"
                          :error-messages="errors"
                          prepend-icon="mdi-account"
                          maxlength="40"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|email|max:60"
                        name="email"
                      >
                        <v-text-field
                          v-model="signup.email"
                          color="primary"
                          :label="$tc('email', 1) + '*'"
                          :placeholder="$tc('placeholders.' + signup.country + '.email', 1)"
                          :error-messages="errors"
                          prepend-icon="mdi-email"
                          type="email"
                          autocomplete="username"
                          maxlength="60"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|min:8|max:20"
                        name="password"
                      >
                        <v-text-field
                          v-model="signup.password"
                          color="primary"
                          :label="$tc('password', 1) + '*'"
                          :placeholder="$tc('placeholders.' + signup.country + '.password', 1)"
                          :error-messages="errors"
                          prepend-icon="mdi-key"
                          :type="showPassword ? 'text' : 'password'"
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          autocomplete="new-password"
                          maxlength="20"
                          validate-on-blur
                          @click:append="showPassword = !showPassword"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|min:8|max:20|confirmed:password"
                        name="password2"
                      >
                        <v-text-field
                          v-model="signup.password2"
                          color="primary"
                          :label="$tc('password2', 1) + '*'"
                          :placeholder="$tc('placeholders.' + signup.country + '.password2', 1)"
                          :error-messages="errors"
                          prepend-icon="mdi-key"
                          :type="showPassword ? 'text' : 'password'"
                          autocomplete="new-password"
                          maxlength="20"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <span class="caption grey--text text--lighten-1">*) {{ $tc('mandatory') }}</span>
                    </v-col>
                  </v-row>
                </form>
              </validation-observer>
            </v-tab-item>

            <v-tab-item class="pb-6">
              <validation-observer
                ref="customer"
                v-slot="{}"
              >
                <form>
                  <v-row
                    class="mx-auto"
                    justify="space-around"
                    style="max-width: 500px;"
                  >
                    <v-col cols="12">
                      <div class="text-center display-1 font-weight-light">
                        {{ $tc('wizards.customer', 1) }}
                      </div>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|min:3|max:40"
                        name="company"
                      >
                        <v-text-field
                          v-model="signup.company"
                          :error-messages="errors"
                          color="primary"
                          :label="$tc('company', 1) + '*'"
                          :placeholder="$tc('placeholders.' + signup.country + '.company', 1)"
                          prepend-icon="mdi-domain"
                          maxlength="40"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>

                    <v-col cols="6">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="fields[signup.country].postalcode.rules"
                        name="postalcode"
                      >
                        <v-text-field
                          v-model="signup.postalcode"
                          :error-messages="errors"
                          color="primary"
                          :label="$tc('postalcode', 1) + '*'"
                          :placeholder="$tc('placeholders.' + signup.country + '.postalcode', 1)"
                          prepend-icon="mdi-mailbox"
                          :minlength="fields[signup.country].postalcode.minlength"
                          :maxlength="fields[signup.country].postalcode.maxlength"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>

                    <v-col cols="3">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|numeric|min:1|min_value:1|max:5|max_value:99999"
                        name="housenumber"
                      >
                        <v-text-field
                          v-model="signup.housenumber"
                          :error-messages="errors"
                          color="primary"
                          :label="$tc('housenumber', 1) + '*'"
                          :placeholder="$tc('placeholders.' + signup.country + '.housenumber', 1)"
                          prepend-icon="mdi-format-header-pound"
                          min="1"
                          max="9999"
                          minlength="1"
                          maxlength="5"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>

                    <v-col cols="3">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="max:5"
                        name="housenumberextension"
                      >
                        <v-text-field
                          v-model="signup.housenumberextension"
                          :error-messages="errors"
                          color="primary"
                          :label="$tc('extension', 1)"
                          :placeholder="$tc('placeholders.' + signup.country + '.housenumberextension', 1)"
                          validate-on-blur
                          maxlength="5"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|min:1|max:28"
                        name="street"
                      >
                        <v-text-field
                          v-model="signup.street"
                          :error-messages="errors"
                          color="primary"
                          :label="$tc('street', 1) + '*'"
                          :placeholder="$tc('placeholders.' + signup.country + '.street', 1)"
                          prepend-icon="mdi-map-marker"
                          maxlength="28"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|min:2|max:30"
                        name="city"
                      >
                        <v-text-field
                          v-model="signup.city"
                          :error-messages="errors"
                          color="primary"
                          :label="$tc('city', 1) + '*'"
                          :placeholder="$tc('placeholders.' + signup.country + '.city', 1)"
                          prepend-icon="mdi-city"
                          maxlength="30"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      v-if="fields[signup.country].coc.visibility"
                      cols="12"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="fields[signup.country].coc.rules"
                        name="coc"
                      >
                        <v-text-field
                          v-model="signup.coc"
                          :error-messages="errors"
                          color="primary"
                          :label="$tc('coc', 1) + ' ' + $tc('number', 1) + '*'"
                          :placeholder="$tc('placeholders.' + signup.country + '.coc', 1)"
                          prepend-icon="mdi-bank-check"
                          :minlength="fields[signup.country].coc.minlength"
                          :maxlength="fields[signup.country].coc.maxlength"
                          validate-on-blur
                        >
                          <template
                            v-if="fields[signup.country].coc.search"
                            #append-outer
                          >
                            <v-btn
                              color="grey darken-2"
                              small
                              @click="cocSearch()"
                            >
                              {{ $tc('search', 1) }}
                              <v-icon right>
                                mdi-open-in-new
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col
                      v-if="fields[signup.country].vat.visibility"
                      cols="12"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="fields[signup.country].vat.rules"
                        name="vat"
                      >
                        <v-text-field
                          v-model="signup.vat"
                          :error-messages="errors"
                          color="primary"
                          :label="$tc('vat', 1) + ' ' + $tc('number', 1) + '*'"
                          :placeholder="$tc('placeholders.' + signup.country + '.vat', 1)"
                          prepend-icon="mdi-cash-plus"
                          :minlength="fields[signup.country].vat.minlength"
                          :maxlength="fields[signup.country].vat.maxlength"
                          validate-on-blur
                        >
                          <template
                            v-if="fields[signup.country].vat.check"
                            #append-outer
                          >
                            <v-btn
                              color="grey darken-2"
                              small
                              :disabled="errors.length > 0 || signup.vat.length < fields[signup.country].vat.minlength || signup.vat.length > fields[signup.country].vat.maxlength"
                              @click="validateVat()"
                            >
                              {{ $tc('check', 1) }}
                              <v-icon
                                right
                                :color="checkVat"
                              >
                                mdi-checkbox-marked-outline
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <span class="caption grey--text text--lighten-1">*) {{ $tc('mandatory') }}</span>
                    </v-col>
                  </v-row>
                </form>
              </validation-observer>
            </v-tab-item>

            <v-tab-item
              v-if="!followUp"
              class="pb-6"
            >
              <v-row
                class="mx-auto"
                justify="space-around"
                style="max-width: 500px;"
              >
                <v-col cols="12">
                  <div class="text-center display-1 grey--text font-weight-light">
                    {{ $tc('wizards.confirmation', 1) }}
                  </div>
                </v-col>

                <v-col cols="12">
                  <div class="display-2">
                    {{ $tc('content', 1) }}
                  </div>
                </v-col>

                <v-col
                  v-if="signup.licensed !== null"
                  cols="12"
                >
                  <v-text-field
                    :value="$tc(licenses[signup.licensed].type, 1)"
                    :label="$tc('fields.licensed', 1)"
                    :prepend-icon="licenses[signup.licensed].icon"
                    dense
                    disabled
                  />
                </v-col>

                <v-col
                  v-if="signup.selectability !== null"
                  cols="12"
                >
                  <v-text-field
                    :value="$tc(selectabilities[signup.selectability].type, 1)"
                    :label="$tc('fields.selectability', 1)"
                    :prepend-icon="selectabilities[signup.selectability].icon"
                    dense
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <div class="display-2">
                    {{ $tc('account', 1) }}
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :value="signup.contact"
                    :label="$tc('contact', 1)"
                    prepend-icon="mdi-account"
                    dense
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :value="signup.email"
                    :label="$tc('email', 1)"
                    prepend-icon="mdi-email"
                    dense
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :value="signup.password"
                    :label="$tc('password', 1)"
                    type="password"
                    prepend-icon="mdi-key"
                    dense
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :value="selectedLanguage.text"
                    :label="$tc('language', 1)"
                    prepend-icon="mdi-translate"
                    dense
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <div class="display-2">
                    {{ $tc('customer', 1) }}
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :value="signup.company"
                    :label="$tc('company', 1)"
                    prepend-icon="mdi-domain"
                    dense
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :value="signup.country"
                    :label="$tc('country', 1)"
                    prepend-icon="mdi-web"
                    dense
                    disabled
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    :value="signup.postalcode"
                    :label="$tc('postalcode', 1)"
                    prepend-icon="mdi-mailbox"
                    dense
                    disabled
                  />
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :value="signup.housenumber"
                    :label="$tc('housenumber', 1)"
                    prepend-icon="mdi-format-header-pound"
                    dense
                    disabled
                  />
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :value="signup.housenumberextension"
                    :label="$tc('extension', 1)"
                    dense
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :value="signup.street"
                    :label="$tc('street', 1)"
                    prepend-icon="mdi-map-marker"
                    dense
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :value="signup.city"
                    :label="$tc('city', 1)"
                    prepend-icon="mdi-city"
                    dense
                    disabled
                  />
                </v-col>

                <v-col
                  v-if="fields[signup.country].coc.visibility"
                  cols="12"
                >
                  <v-text-field
                    :value="signup.coc"
                    :label="$tc('coc', 1) + ' ' + $tc('number', 1)"
                    prepend-icon="mdi-bank-check"
                    dense
                    disabled
                  />
                </v-col>

                <v-col
                  v-if="fields[signup.country].vat.visibility"
                  cols="12"
                >
                  <v-text-field
                    :value="signup.vat"
                    :label="$tc('vat', 1) + ' ' + $tc('number', 1)"
                    prepend-icon="mdi-cash-plus"
                    dense
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <div class="body-2">
                    {{ $tc('recurringpayment', 1) }}
                  </div>
                </v-col>

                <v-col cols="12">
                  <validation-observer
                    ref="agree"
                    v-slot="{}"
                  >
                    <form>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="agree"
                      >
                        <v-checkbox
                          v-model="signup.agree"
                          :error-messages="errors"
                          value="1"
                          :label="$tc('agree', 1) + '*'"
                          type="checkbox"
                          dense
                          required
                        />
                      </validation-provider>
                    </form>
                  </validation-observer>
                </v-col>

                <v-col cols="12">
                  <span class="caption grey--text text--lighten-1">*) {{ $tc('mandatory') }}</span>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item
              v-else
              class="pb-6"
            >
              <v-row
                class="mx-auto"
                justify="space-around"
                style="max-width: 500px;"
              >
                <v-col cols="12">
                  <div class="text-center display-1 font-weight-light">
                    {{ $tc('wizards.redirect', 1) }}
                  </div>
                </v-col>

                <v-col cols="12">
                  <div class="body-1">
                    {{ $tc("recurringredirect", 1) }}
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
          </base-material-wizard>
        </v-slide-y-transition>
      </v-col>
      <v-col cols="12">
        <br>&nbsp;
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PagesSignup',

    data: function () {
      return {
        store: this.$store,
        signup: {
          language: '',
          licensed: null,
          selectability: null,
          contact: '',
          email: '',
          password: '',
          password2: '',
          company: '',
          country: 'NL',
          postalcode: '',
          housenumber: '',
          housenumberextension: '',
          street: '',
          city: '',
          coc: '',
          vat: '',
          agree: '',
        },
        showPassword: false,
        checkVat: '',
        licenses: [
          {
            icon: 'mdi-currency-usd-off',
            type: 'unlicensed',
          },
          {
            icon: 'mdi-currency-usd',
            type: 'licensed',
          },
        ],
        selectabilities: [
          {
            icon: 'mdi-numeric-1-box',
            type: 'single',
          },
          {
            icon: 'mdi-plus-box-multiple',
            type: 'multi',
          },
        ],
        pricing: [
          [ // 0
            { // 0
              amount: '239',
            },
            { // 1
              amount: '299',
            },
          ],
          [ // 1
            { // 0
              amount: '419',
            },
            { // 1
              amount: '479',
            },
          ],
        ],
        countries: [
          {
            text: 'Nederland',
            value: 'NL',
          },
          {
            text: 'België',
            value: 'BE',
          },
          {
            text: 'Deutschland',
            value: 'DE',
          },
        ],
        fields: {
          BE: {
            postalcode: {
              visibility: true,
              rules: 'required|digits:4',
              minlength: 4,
              maxlength: 4,
              type: 'number',
              example: '1234',
            },
            coc: {
              visibility: true,
              rules: 'required|digits:10',
              minlength: 10,
              maxlength: 10,
              type: 'number',
              example: '1234567890',
              search: 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=610&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek&searchWord=',
            },
            vat: {
              visibility: true,
              rules: 'required|alpha_num|min:12|max:12',
              minlength: 12,
              maxlength: 12,
              type: 'text',
              example: 'BE0123456789',
              check: true,
            },
          },
          DE: {
            postalcode: {
              visibility: true,
              rules: 'required|digits:5',
              minlength: 5,
              maxlength: 5,
              type: 'number',
              example: '12345',
            },
            coc: {
              visibility: false,
              rules: 'digits:10',
              minlength: 10,
              maxlength: 10,
              type: 'number',
              example: '1234567890',
              search: false,
            },
            vat: {
              visibility: true,
              rules: 'required|alpha_num|min:11|max:11',
              minlength: 11,
              maxlength: 11,
              type: 'text',
              example: 'DE123456789',
              check: true,
            },
          },
          NL: {
            postalcode: {
              visibility: true,
              rules: 'required|alpha_num',
              minlength: 6,
              maxlength: 6,
              type: 'text',
              example: '1234AB',
            },
            coc: {
              visibility: true,
              rules: 'required|digits:8',
              minlength: 8,
              maxlength: 8,
              type: 'number',
              example: '12345678',
              search: 'https://www.kvk.nl/zoeken/?source=all&q=',
            },
            vat: {
              visibility: false,
              rules: 'alpha_num|min:14|max:14',
              minlength: 14,
              maxlength: 14,
              type: 'text',
              example: 'NL123456789B01',
              check: true,
            },
          },
        },
        tab: 0,
        tabs: ['preliminary', 'content', 'account', 'customer', 'confirmation'],
        loading: false,
        followUp: null,
        paymentUrl: null,
      }
    },
    computed: {
      availableSteps () {
        const steps = [0]

        if (this.loading || this.followUp) return steps

        if (
          this.signup.country &&
          this.signup.language
        ) steps.push(1)

        if (
          this.signup.licensed >= 0 &&
          this.signup.selectability >= 0 &&
          steps.includes(1)
        ) steps.push(2)

        if (
          this.signup.contact.length >= 5 &&
          this.signup.contact.length <= 40 &&
          this.signup.email &&
          this.signup.email.length <= 60 &&
          (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.signup.email)) &&
          this.signup.password.length >= 8 &&
          this.signup.password.length <= 20 &&
          this.signup.password2 === this.signup.password &&
          steps.includes(2)
        ) steps.push(3)

        if (
          this.signup.company.length >= 3 &&
          this.signup.company.length <= 40 &&
          (
            this.signup.postalcode.length >= this.fields[this.signup.country].postalcode.minlength &&
            this.signup.postalcode.length <= this.fields[this.signup.country].postalcode.maxlength
          ) &&
          this.signup.housenumber >= 1 &&
          this.signup.housenumber <= 99999 &&
          this.signup.street.length >= 1 &&
          this.signup.street.length <= 28 &&
          this.signup.city.length >= 2 &&
          this.signup.city.length <= 30 &&
          (
            !this.fields[this.signup.country].vat.visibility ||
            (
              this.fields[this.signup.country].vat.visibility &&
              this.signup.vat.length >= this.fields[this.signup.country].vat.minlength &&
              this.signup.vat.length <= this.fields[this.signup.country].vat.maxlength
            )
          ) &&
          (
            !this.fields[this.signup.country].coc.visibility ||
            (
              this.fields[this.signup.country].coc.visibility &&
              this.signup.coc.length >= this.fields[this.signup.country].coc.minlength &&
              this.signup.coc.length <= this.fields[this.signup.country].coc.maxlength
            )
          ) &&
          steps.includes(3)
        ) steps.push(4)

        if (
          this.signup.agree &&
          steps.includes(4)
        ) steps.push(5)

        return steps
      },
      selectedLanguage () {
        const language = this.store.state.locales.find(l => l.value === this.store.state.locale)
        return language
      },
    },
    watch: {
      '$store.state.locale' () {
        this.signup.language = this.selectedLanguage
      },
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        var self = this
        const q = self.$route.query
        Object.keys(q).forEach(function (key) {
          if (key === 'licensed' || key === 'selectability') {
            self.signup[key] = parseInt(q[key])
          } else {
            self.signup[key] = q[key]
          }
        })
        self.signup.language = self.$i18n.locale
      },
      next () {
        if (this.tab === this.tabs.length - 1) {
          this.loading = true
          this.createSubscription()
        } else {
          if (this.tab === 2) {
            this.loading = true
            this.validateAccount()
          } else if (this.tab === 3) {
            this.loading = true
            this.validateCustomer()
          } else {
            this.tab++
          }
        }
      },
      previous () {
        this.tab--
        this.signup.agree = ''
      },
      validateAccount () {
        var self = this
        self.$http.post('/api/subscription/validate/user', self.signup).then(function (response) {
          if (self.store.state.result === 422) {
            var errors = {}
            if (self.store.state.message.contact) errors.contact = [self.$t('invalids.' + self.store.state.message.contact[0].split('.').join(''))]
            if (self.store.state.message.email) errors.email = [self.$t('invalids.' + self.store.state.message.email[0].split('.').join(''))]
            if (self.store.state.message.password) errors.password = [self.$t('invalids.' + self.store.state.message.password[0].split('.').join(''))]
            self.$refs.account.setErrors(errors)
            self.loading = false
          } else {
            self.tab++
            self.showPassword = false
            self.loading = false
          }
        }, function (response) {
          self.loading = false
        })
      },
      validateCustomer () {
        var self = this
        self.$http.post('/api/subscription/validate/customer', self.signup).then(function (response) {
          if (self.store.state.result === 422) {
            var errors = {}
            if (self.store.state.message.city) errors.city = [self.$t('invalids.' + self.store.state.message.city[0].split('.').join(''))]
            if (self.store.state.message.company) errors.company = [self.$t('invalids.' + self.store.state.message.company[0].split('.').join(''))]
            if (self.store.state.message.country) errors.country = [self.$t('invalids.' + self.store.state.message.country[0].split('.').join(''))]
            if (self.store.state.message.housenumber) errors.housenumber = [self.$t('invalids.' + self.store.state.message.housenumber[0].split('.').join(''))]
            if (self.store.state.message.postalcode) errors.postalcode = [self.$t('invalids.' + self.store.state.message.postalcode[0].split('.').join(''))]
            if (self.store.state.message.street) errors.street = [self.$t('invalids.' + self.store.state.message.street[0].split('.').join(''))]
            if (self.store.state.message.vat) errors.vat = [self.$t('invalids.' + self.store.state.message.vat[0].split('.').join(''))]
            self.$refs.customer.setErrors(errors)
            self.loading = false
          } else {
            self.tab++
            self.loading = false
          }
        }, function (response) {
          self.loading = false
        })
      },
      createSubscription () {
        var self = this
        self.$http.post('/api/subscription/signup', self.signup).then(function (response) {
          if (self.store.state.result === 422) {
            var errors = {}
            if (self.store.state.message.contact) errors.contact = [self.$t('invalids.' + self.store.state.message.contact[0].split('.').join(''))]
            if (self.store.state.message.email) errors.email = [self.$t('invalids.' + self.store.state.message.email[0].split('.').join(''))]
            if (self.store.state.message.password) errors.password = [self.$t('invalids.' + self.store.state.message.password[0].split('.').join(''))]
            if (self.store.state.message.city) errors.city = [self.$t('invalids.' + self.store.state.message.city[0].split('.').join(''))]
            if (self.store.state.message.company) errors.company = [self.$t('invalids.' + self.store.state.message.company[0].split('.').join(''))]
            if (self.store.state.message.country) errors.country = [self.$t('invalids.' + self.store.state.message.country[0].split('.').join(''))]
            if (self.store.state.message.housenumber) errors.housenumber = [self.$t('invalids.' + self.store.state.message.housenumber[0].split('.').join(''))]
            if (self.store.state.message.postalcode) errors.postalcode = [self.$t('invalids.' + self.store.state.message.postalcode[0].split('.').join(''))]
            if (self.store.state.message.street) errors.street = [self.$t('invalids.' + self.store.state.message.street[0].split('.').join(''))]
            if (self.store.state.message.vat) errors.vat = [self.$t('invalids.' + self.store.state.message.vat[0].split('.').join(''))]
            if (self.store.state.message.licensed) errors.licensed = [self.$t('invalids.' + self.store.state.message.licensed[0].split('.').join(''))]
            if (self.store.state.message.selectability) errors.selectability = [self.$t('invalids.' + self.store.state.message.selectability[0].split('.').join(''))]
            if (self.store.state.message.service) errors.service = [self.$t('invalids.' + self.store.state.message.service[0].split('.').join(''))]
            self.$refs.account.setErrors(errors)
            self.$refs.customer.setErrors(errors)
            self.loading = false
          } else {
            self.followUp = response.data
            if (self.followUp.redirect === 'payment') {
              self.$http.get('/api/subscription/payment/init?subscription=' + self.followUp.subscription.id + '&returnUrl=' + self.store.state.clientHostname + '/signup/payment').then(function (response) {
                self.paymentUrl = response.data
                window.location.href = response.data
                self.loading = false
              }, function (response) {
                self.loading = false
              })
            } else if (self.followUp.redirect === 'login') {
              this.$router.push({ name: 'Login' })
            } else {
              // todo
            }
            self.loading = false
          }
        }, function (response) {
          self.loading = false
        })
      },
      cocSearch () {
        window.open(this.fields[this.signup.country].coc.search + this.signup.company, '_blank')
      },
      validateVat () {
        var self = this
        var vat = { field: 'vat', value: self.signup.vat }
        self.loading = true
        self.$http.post('/api/subscription/validate/field', vat).then(function (response) {
          if (self.store.state.result === 422) {
            var errors = {}
            if (self.store.state.message.value) errors.vat = [self.$t('invalids.' + self.store.state.message.value[0].split('.').join('')) + ' (' + self.$tc('placeholders.' + self.signup.country + '.vat', 1) + ')']
            self.$refs.customer.setErrors(errors)
            self.loading = false
          } else {
            self.checkVat = 'primary'
            self.loading = false
          }
        }, function (response) {
          self.loading = false
        })
      },
      resetCountryRules () {
        this.signup.postalcode = ''
        if (this.fields[this.signup.country].coc.visibility === false) this.signup.coc = ''
        if (this.fields[this.signup.country].vat.visibility === false) this.signup.vat = ''
        this.checkVat = ''
      },
    },
  }
</script>

  <style lang="sass">
    .v-card.v-card.v-card--account
      border-color: currentColor
      border-width: 4px

      .v-icon
        color: inherit

    .v-card--account,
    .v-card--account:before
      border-radius: 50%
  </style>
